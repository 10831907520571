<template v-cloak>
  <div>
    <div class="create-container">
      <div class="create-nav-wrapper">
        <div class="create-nav app-inner-container">
          <div class="create-nav-left">
            <div class="nav-home" @click="onGoBackClick">
              <svg-icon icon-class="home" />
            </div>
            <div class="separator"></div>
            <div class="nav-title">
              <el-input v-model="name" v-focus placeholder="请输入表单名称" class="title_editor_input" size="mini" ref="nameInput" @input="oninputName" @focus="onFocusName" @blur="onBlurName" v-if="isInput"></el-input>
              <span style="color: #3476F0;" ref="fixName" @click="onClickNameEvent" v-else>{{name}}</span>
              <!-- <svg-icon icon-class="formRename" style="margin-left: 4px;" @click="onClickNameEvent" /> -->
            </div>
          </div>
          <div class="create-nav-center">
            <ul class="tab-menu">
              <li @click="onTabClick(1)" :class="{active:cur==1}">基本信息</li>
              <li @click="onTabClick(2)" :class="{active:cur==2}">页面设计</li>
              <li @click="onTabClick(3)" :class="{active:cur==3}">扩展设置</li>
            </ul>
          </div>
          <div class="create-nav-right">
            <el-button type="warning" @click="onConfirmSaveClick()" :disabled="saveBtnClick">保存</el-button>
            <el-button type="primary" @click="onConfirmReleaseClick" :disabled="saveBtnClick" v-show="isNeedRelease">发布</el-button>
          </div>
        </div>
      </div>
      <div class="form-new-design-wrapper " style="width:100%;height: 100%;overflow:hidden">
        <div class="form-new-design" style="width:100%;height: 100%;overflow:hidden">
          <div v-show="cur == 1" style="height:100%;">
            <info ref="designFormInfo" :formId="createFormId" :userType="userInfo.type" @saveBtnClick="getMsgFormSon" :agencyId="userInfo.adminAgencyId" />
          </div>
          <div v-show="cur == 2" style="height:100%;background-color:white!important;text-align:left">
            <v-form-designer ref="vformDesigner" :optionData="optionData"></v-form-designer>
          </div>
          <div v-show="cur == 3" style="height:100%;text-align:left;">
            <set ref="designFormSet" :formId="createFormId" :isEdit="isEdit" :agencyId="userInfo.adminAgencyId" :projectId="projectId" />
          </div>
        </div>
      </div>
    </div>
    <fillSelector ref="fillSelector"></fillSelector>
  </div>

</template>

<script>
import { agencyInfo } from '@/api/system/agency'

import { aliUploader } from '@/utils/oss'
import Info from './components/info'
import Set from './components/set'
import Design from './components/design'
import { getSessionStorageObj, getLocalStorageObj } from '@/utils/db'

import { getAgency, getAgencyUser, agencyUserList, agencyDeptList, agencyRoleList } from '@/api/system/agency'
import { userAgencyMyList } from '@/api/system/user'
import { httpFormBase, httpFormField, httpSaveFormFieldV2, 
  moveFormToGroup, httpFormSubmit, httpFormPay, httpFormViewAuth, httpFormExport, httpFormAuditAuth, httpFormFillAuth, formName, formDetail, httpFormRelease, deleleteFile, saveFormAutocodeRule } from '@/api/system/form'
import { getNowFormatDate } from '@/utils/whale'
import fillSelector from './components/fillSelector'

import designMixins from './mixins/designMixins'
export default {
  name: 'New',
  mixins: [designMixins],
  components: {
    Info,
    Design,
    Set,
    fillSelector
  },
  data () {
    return {
      logoImg: 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/pc_logo.png',
      cur: 1,
      userInfo: {},
      name: '未命名表单',
      width: 0,
      detailInfo: {},
      otherDetailInfo: {},
      fillStartTime: '',
      fillEndTime: '',
      formFieldList: [],
      reserveList: [],
      check: '',
      formId: null,
      projectId: 0, // 单个创建表单
      isFromDetail: false, // 从详情过来
      createTime: null,
      isInput: false,
      saveBtnClick: false,
      // 新版
      createFormId: null, // 是否已经创建表单，表单id 989
      isEdit: true,
      isNeedRelease: false, // 是否需要发布
      isClickSaveBtn: false, // 是否点击保存按钮
      isClickBackBtn: false, // 是否点击返回按钮
      isClickTabBtn: false, // 是否点击tab按钮
      isSaveAndReleaseBtn: false, // 是否是保存并发布
      formInfoCur: null,
      currentAgencyId: 0,
      formJson: {},
      formData: {},
      optionData: {}
    }
  },
  // 因为 element ui 的input 是 通过 div 来封装的 所以  用 原生的 input获取焦点的方法是不可行的，需要通过 自定义局部命令来实现
  directives: {
    focus: {
      inserted: function (el) {
        el.children[0].focus()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.onToFormCreate()
    })
  },
  created () {
    let sessionStorageUser = getSessionStorageObj('users')
    // this.userInfo = sessionStorageUser
    this.currentAgencyId =  getSessionStorageObj('agencyId')
    this.$store.dispatch('GetUserInfo').then((res) => {
      this.userInfo = res.data
    })

    if (!this.isBlank(this.$route.query.type)) {
      this.isFromDetail = true
      this.projectId = this.decode(this.$route.query.id)
    }

    const userToken = this.cookie.get('User-Token')
    this.optionData = {
      userToken: userToken,
      apiUrl: `${process.env.VUE_APP_BASE_API_URL}`,
      formId: this.formId
    }
    this.optionData.formDataSelectHandler = this.showDataSelect
    this.optionData.formDataSelectAutoFillHandler = this.$parent.showDataAutoSelect;
    this.optionData.formDataViewHandler = this.showDataView
    this.optionData.formDataCardRender = this.dataCardRender()
    this.optionData.formCreateUser = sessionStorageUser
    this.optionData.loadAgencyUserExtendFieldsHandler = getAgency
    this.optionData.loadAgencyUserListHandler = agencyUserList
    this.optionData.loadAgencyUserHandler = getAgencyUser
    this.optionData.api_agency = { agencyUserList, agencyDeptList, agencyRoleList, userAgencyMyList }
    this.optionData.fileViewUrl = process.env.VUE_APP_BASE_FILE_PREVIEW
    this.optionData.formAgencyId = this.currentAgencyId
    this.optionData.formData = {};



    this.optionData.aliOSSUpload = aliUploader
    this.optionData.aliOSSUploadConfig = {  //OK
      formId: this.formId || 'formId',
      agencyId: sessionStorageUser.adminAgencyId,
      userId: sessionStorageUser.userId || 'userId',
      uploadPath: 'fillFile/{agencyId}/{formId}/{userId}/{fieldName}/',
    }
    this.optionData.fileDelete = this.fileDelete

    this.optionData.isUserAdmin = getSessionStorageObj('users').type > 1
  },
  beforeDestroy () { },
  methods: {
    handleInput ($event) {
      const obj = $event.target
      obj.innerHTML = obj.innerHTML
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
        .replace(/<[^>]+?>/g, '')
        .replace(/\s+/g, '')
        .replace(/ /g, '')
        .replace(/>/g, '')
      if (window.getSelection) {
        // ie11 10 9 ff safari
        obj.focus() // 解决ff不获取焦点无法定位问题
        const range = window.getSelection() // 创建range
        range.selectAllChildren(obj) // range 选择obj下所有子内容
        range.collapseToEnd() // 光标移至最后
      } else if (document.selection) {
        // ie10 9 8 7 6 5
        const range = document.selection.createRange() // 创建选择对象
        range.moveToElementText(obj) // range定位到obj
        range.collapse(false) // 光标移至最后
        range.select()
      }
    },
    handleBlur ($event) {
      this.name = $event.target.innerHTML
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
        .replace(/<[^>]+?>/g, '')
        .replace(/\s+/g, '')
        .replace(/ /g, '')
        .replace(/>/g, '')
    },
    handleDelete (e) { },
    // 点击修改名称 input输入框显示
    onClickNameEvent () {
      this.isInput = true
    },
    // 监听表单名称修改
    oninputName (value) {
      const self = this
      self.name = value
    },
    // 监听表单名称修改 获取焦点
    onFocusName () {
      this.$refs.nameInput.focus()
      this.$refs.nameInput.select()
    },
    // 监听表单名称修改 失去焦点
    onBlurName () {
      if (this.isBlank(this.name)) {
        this.name = '未命名表单'
        this.width = 90
      }
      if (this.name.length > 50) {
        this.$message({
          type: 'warning',
          message: '表单名称仅支持输入1-50个字！'
        })
        return
      }
      const param = {
        formId: this.createFormId,
        name: this.name
      }
      formName(JSON.stringify(param)).then((response) => {
        if (response.code == 200) {
          this.isInput = false
          this.isFocus = false
        }
      })
    },

    onFormInfoTabClick (index) {
      this.formInfoCur = index
    },
    getAgencyInfo () { },

    getMsgFormSon (data) {
      this.saveBtnClick = data
    },
    // 新版 --- 一进页面就创建表单
    onToFormCreate () {
      const _this = this
      const formInfo = _this.$refs.designFormInfo.designForm
      const param = {
        name: _this.name,
        cover: formInfo.cover,
        founder: formInfo.founder,
        formCustomAddParam: {
          details: formInfo.detailsLabel,
          file: formInfo.fileLabel
        },
        details: formInfo.details,
        fileList: !_this.isBlank(formInfo.fileList) ? formInfo.fileList : [],
        projectId: _this.projectId,
        agencyId: this.currentAgencyId
      }
      if (!_this.isBlank(formInfo.formId)) {
        param.formId = formInfo.formId
        this.optionData.formId = formInfo.formId
      }
      // console.log('创建保存表单基本信息param', param)

      httpFormBase(JSON.stringify(param)).then((response) => {
        if (response.code === 200) {
          _this.createFormId = response.data
          _this.optionData.formId = response.data
          formDetail(_this.createFormId).then((response) => {
            _this.formDetail = response.data
            if (response.data.status == '1') {                
                _this.isNeedRelease = true
            }
          })

          if(_this.$route.query.groupId){
            moveFormToGroup(_this.createFormId,_this.$route.query.groupId)
          }



        } else {
          _this.msgError(response.msg)
        }
      })
    },
    setFormV2IsSaved () {
      this.$refs.vformDesigner.isDesignSaved(true)
    },
    onSavePrompt (saveCallback, noSaveCallback) {
      this.$confirm('表单信息有修改，是否保存？', '提示', {
        confirmButtonText: '保存并继续',
        cancelButtonText: '不保存',
        type: 'warning',
        distinguishCancelAndClose: true // 区分取消与关闭
      })
        .then(() => {
          saveCallback()
        })
        .catch((actionS) => {
          if (actionS == 'cancel') {
            noSaveCallback()
            // this.$router.go(-1);
          } else {
            // 如果点击的是右上角关闭，则什么都不做
          }
        })
    },
    // 新版 --- 创建表单逻辑
    onTabClick (index) {
      this.cur = index
    },
    // 新版 --- 保存表单基本信息
    saveFormBase (callback) {
      this.$refs.designFormInfo.saveFormBase(
        {
          name: this.name,
          projectId: this.projectId
        },
        (formId) => {
          this.formId = formId
          if (callback) callback(this.formId)
        }
      )
    },
    saveFormFieldV2 (callback) {
      const _this = this
      const fromPost = {
        ...this.$refs.vformDesigner.getFormJson(),
        projectId: _this.projectId
      }

      if (!_this.isBlank(_this.createFormId)) {
        fromPost.formId = _this.createFormId
      }

      httpSaveFormFieldV2(fromPost).then((response) => {
        if (response.code === 200) {
          if (callback) callback(this.formId)
        } else {
          _this.msgError(response.msg)
        }
      })
    }, // 新版 --- 保存表单提交设置
    saveFormSubmit (succ, fail) {
      this.$refs.designFormSet.saveFormSubmit(succ, fail)
    },
    // 新版 --- 保存
    onConfirmSaveClick (callback) {
      const _this = this
      const actions = []
      const isEditCur = this.checkChangedPage()

      const savePage1 = () => {
        if (!isEditCur['1']) {
          return Promise.resolve(true)
        } else {
          return new Promise((resolve, reject) => {
            _this.saveFormBase((res) => {
              resolve(res)
            })
          })
        }
      }
      const savePage2 = () => {
        if (!isEditCur['2']) {
          return Promise.resolve(true)
        } else {
          return new Promise((resolve, reject) => {
            _this.saveFormFieldV2((res) => {
              resolve(res)
            })
          })
        }
      }
      const savePage3 = () => {
        if (!isEditCur['3']) {
          return Promise.resolve(true)
        } else {
          return new Promise((resolve, reject) => {
            _this.saveFormSubmit(
              (res) => {
                resolve(res)
              },
              () => {
                reject()
              }
            )
          })
        }
      }
      const saveAutoCode = ([page1Res, page2Res, page3Res]) => {
          this.setViewList(this.formId);
        const fieldList = this.$refs.vformDesigner.getFieldWidgets();
        const autocode = fieldList.find(x => x.field && x.field.subtype === 'autocode')
        if (!autocode) {
          return new Promise((resolve, reject) => {

        if (!this.createFormId) { Promise.resolve(); return; }
            // 缺保存自动编号的接口
            saveFormAutocodeRule({
              formId: this.createFormId,
              autoCodeRule: '',
              autocodeStatus: false
            }).then(() => {
              resolve()
            }).catch(() => {
              reject()
            })
          })
        }
        return new Promise((resolve, reject) => {
        if (!this.createFormId) { Promise.resolve(); return; }
          // 缺保存自动编号的接口
          saveFormAutocodeRule({
            formId: this.createFormId,
            autoCodeRule: JSON.stringify(autocode.field.options.coderule),
            autocodeStatus: autocode.field.options.counterReset||false
          }).then(() => {
            resolve()
          }).catch(() => {
            reject()
          })
        })
      }


      Promise.all([savePage1(), savePage2(), savePage3()])
        .then((values) => {
          saveAutoCode(values).then(_ => {
            if (callback) callback()
            else _this.msgSuccess('保存成功')
          })
        })
        .catch((reason) => {
          console.error(reason)
        })
    },
    // 新版 --- 发布
    onConfirmReleaseClick () {
      const _this = this
      _this.onConfirmSaveClick(() => {
        // _this.httpFormRelease()
        this.httpFormReleaseAndCreateView(this.createFormId)
      })
    },

    // 新版 --- 发布请求
    httpFormRelease () {
      const _this = this
      const param = {
        formId: _this.createFormId
      }
      return new Promise((resolve)=>{
      httpFormRelease(JSON.stringify(param)).then((response) => {
        if (response.code === 200) {
          _this.msgSuccess('发布成功')
          _this.isNeedRelease = false
          // _this.$refs.designFormInfo.isEdit = false
          // _this.$refs.vformDesigner.isDesignSaved(true);
          // _this.$refs.designFormSet.isSetEdit = false
          // _this.isSaveAndReleaseBtn = false
          // _this.isSaveAndReleaseBtn = false
          resolve();
        } else {
          _this.msgError(response.msg)
        }
      })
      })
    },
    // 获取页面修改情况
    checkChangedPage () {
      const isEditCur = {
        1: this.$refs.designFormInfo.isEdit,
        2: !this.$refs.vformDesigner.isDesignSaved(),
        3: this.$refs.designFormSet.isSetEdit
      }
      return isEditCur
    },
    // 新版 --- 返回
    onGoBackClick () {
      const _this = this
      const actions = []
      let isEdit = false
      const isEditCur = this.checkChangedPage()

      Object.keys(isEditCur).forEach((x) => {
        isEdit = isEdit || isEditCur[x]
      })

      // 保护不要从界面直接退到登录
      const back = ()=>{
         if(this.$router.options.from.path ==='/login'){
          this.$router.push({
            path: '/index'
          })
          }else{
              this.$router.go(-1);
          }
      }

      if (isEdit) {
        _this.onSavePrompt(
          (/* 保存函数*/) => {
            _this
              .onConfirmSaveClick(() => {
                _this.msgSuccess('保存成功')
                _this.$router.go(-1)
              })
              .catch((reason) => {
                console.error(reason)
              })
          },
          (/* 不保存函数*/) => {
            // _this.$router.go(-1)
            back();
          }
        )
      } else {
        // _this.$router.go(-1)
            back();
      }
    },
    showDataSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      this.$refs.fillSelector.show(fillSelectOption, callback)
    },
    showDataAutoSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      this.$refs.fillSelector.autoFill(fillSelectOption, callback)
    },
    async showDataView (fillSelectOption, fillData, callback) {
      // callback:选择完成后的回调
      console.log(fillSelectOption, fillData)
      // 加载表单配置数据

      const res = await httpGetFormFieldV2(fillSelectOption.formId)

      this.$refs.dlgvFormViewer.showView({
        fillId: fillData.fillId, 
        formId: fillSelectOption.formId, 
        formJson: res.data, 
        formData: fillData, 
        showHiddenWidgets: this.cur != 3
      })
    },
    // 引用table的render，显示dataCard内容
    dataCardRender () {
      const renders = require('@/utils/gridrender/cellRender')
      return renders.renderProvider
    }
  },
  fileDelete (file) {
    deleleteFile(file.fileId).then(res => {

    })
    if (file.thumbFileId) {
      deleleteFile(file.thumbFileId).then(res => {
      })
    }
  },
}
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}
.create-container {
  width: 100%;
  margin: 0 auto;
  position: fixed;

  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .form-new-design-wrapper {
    // position: absolute;
    // top: 76px;
    // bottom: 20px;
    // left: 20px;
    // right: 20px;
    margin-top: 20px;
    text-align: center;

    flex-shrink: 1;
    overflow: hidden;
    display: inline-block;
    overflow: auto;
  }

  .form-new-design {
    margin-top: 10px;
    // position: absolute;
    // top: 76px;
    // bottom: 20px;
    // left: 20px;
    // right: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 0px;
    .container-center {
      display: flex;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      justify-content: center;
    }
  }
  .create-nav-wrapper {
    width: 100%;
    height: 56px;
    background: #fff;
    -webkit-transform: translateZ(0);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  }
  .create-nav {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: auto;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    .create-nav-left {
      height: 56px;
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: left;
      .nav-home {
        cursor: pointer;
        font-size: 20px;
      }
      .separator {
        width: 1px;
        height: 20px;
        border-right: 1px solid #dbdde7;
        margin: 0 16px;
      }
      .nav-title {
        max-width: 350px;
        font-size: 16px;
        font-weight: 500;
        color: #646d7a;
        line-height: 24px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        border-bottom: dashed 1px #bababa;
      }
    }
    .create-nav-center {
      position: relative;
      display: inline-block;

      .tab-menu {
        list-style: none;
        margin: 0;
        padding: 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 56px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        li {
          font-size: 16px;
          color: #333333;
          display: inline-block;
          width: auto;
          height: auto;
          text-align: center;
          vertical-align: middle;
          line-height: 56px;
          border: 0;
          cursor: pointer;
          display: inline-block;
          float: left;
          padding: 0 10px;
          margin: 0 7px;
        }
        .active {
          color: #3476F0;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 52px;
            height: 4px;
            bottom: -0.5px;
            left: 50%;
            margin-left: -26px;
            background: #3476F0;
            border-radius: 3px 3px 0 0;
          }
        }
        .ul-step__arrow {
          display: inline-block;
          margin: 0 60px;
          span {
            display: inline-block;
            width: 20px;
            height: 40px;
            background: url('https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form-next.png')
              no-repeat center center;
            background-size: 18px 12px;
          }
        }
      }
    }
    .create-nav-right {
      flex-shrink: 0;
      width: 300px;
      overflow: hidden;
      text-align: right;
      display: inline-block;
      padding-right:10px;
      box-sizing: border-box;
      // position: absolute;
      // right: 0px;
      // height: 60px;
      // top: 16px;
    }
  }
  .details-nav-center {
    width: 100%;
    margin: auto;
    padding: 0;
    text-align: center;
    background: #f5f7fa;
    li {
      display: inline-block;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      margin-right: 60px;
      font-size: 14px;
      color: #bababa;
      &.active {
        color: #394349;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 100px;
          left: 50%;
          bottom: 0;
          height: 4px;
          background: #0078f8;
          -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
          z-index: 1;
          margin-left: -50px;
        }
      }
    }
  }
}
.title_editor_input {
  width: 100%;
  float: left;
  display: inline-block;
  font-size: 14px !important;
}
.title_editor_input.el-input--medium .el-input__inner {
  width: 100%;
  height: 28px;
  line-height: 28px;
}
.preview_custom {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: 0 0;
  -webkit-transition: background 218ms ease-in-out;
  -moz-transition: background 218ms ease-in-out;
  -o-transition: background 218ms ease-in-out;
  transition: background 218ms ease-in-out;
  background: #f3f4f5;
  z-index: 8096;
  .preview-nav {
    width: 100%;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    height: 60px;
    -webkit-transform: translateZ(0);
    margin: auto;
    border-bottom: 1px solid #dee1e8;
    .preview-nav-left {
      float: left;
      line-height: 60px;
      font-size: 14px;
      color: #404040;
      cursor: pointer;
      .preview-left-logo {
        height: 38px;
        margin-right: 20px;
        img {
          width: 130px;
          height: 38px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .preview-nav-center {
      max-width: 600px;
      margin: auto;
      line-height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      color: #404040;
      text-align: center;
    }
  }
  .preview-content {
    height: auto;
    background: #fff;
    margin: 10px auto;
    flex-direction: column;
    .form-img {
      width: 300px;
      height: 180px;
      img {
        width: 100%;
        max-width: 100%;
        height: 180px;
        border-radius: 6px;
      }
    }
    .form-info {
      width: 100%;
      position: relative;
      padding: 0 0 0 0;
      box-sizing: border-box;
      height: 180px;
      .form-title {
        width: 100%;
        font-size: 22px;
        color: #333333;
        margin: 0;
        line-height: 30px;
        font-weight: 400;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: center;
        .form-title-left {
          flex: 1;
          -webkit-flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          display: -webkit-flex;
          width: 100%;
          justify-content: space-between;
        }
      }
      .form-detail-top-bottom {
        position: absolute;
        bottom: -8px;
        width: 100%;
        .form-date {
          width: 100%;
          font-size: 16px;
          height: 32px;
          line-height: 32px;
          margin: 0;
          color: #404040;
          .fill-date {
            margin: 0;
            padding: 0;
            .is-agency-icon {
              display: inline-block;
              width: 48px;
              height: 18px;
              margin-left: 5px;
              vertical-align: -3px;
            }
          }
        }
        .form-fill-btn {
          position: absolute;
          bottom: 8px;
          right: 0;
        }
      }
    }
    .form-info-tab-bottom {
      width: 100%;
      background: #fff;
      -webkit-box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
      box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
      border-radius: 10px;
      margin-top: 20px;
      .tab-content-nav {
        width: 100%;
        display: flex;
        display: -webkit-flex;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #f2f3f4;
        // padding: 0 20px;
        box-sizing: border-box;
        cursor: pointer;
        .content-nav-title {
          font-size: 16px;
          margin-right: 20px;
          display: inline-block;
          &.active {
            border-bottom: 2px solid #1890ff;
            line-height: 48px;
          }
        }
      }
    }
    .details-title {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 0;
      .title-span {
        font-size: 14px;
        color: #404040;
        line-height: 20px;
      }
      .title-line {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        -moz-flex: 1;
        flex: 1;
        height: 1px;
        background: #e5e5e5;
        margin-left: 20px;
      }
    }
    .details-intro {
      width: 100%;
      font-size: 14px;
      color: #394349;
      word-break: break-all;
      padding: 0 20px 20px;
      box-sizing: border-box;
    }
    .details-file {
      width: 100%;
      padding: 0 20px 20px;
      box-sizing: border-box;
      p {
        cursor: pointer;
        font-size: 13px;
        line-height: 22px;
        margin: 20px 0 0;
        color: #394349;
      }
    }
    .form-field-list {
      margin: 0 auto;
      width: 710px;
      padding: 20px 0;
      .form-field-item {
        width: 100%;
        margin-bottom: 20px;
        .label-title {
          display: inline-block;
          width: 100%;
          font-size: 13px;
          font-weight: 600;
          color: #394349;
          line-height: 18px;
          padding-bottom: 10px;
        }
      }
    }
  }
}
</style>

